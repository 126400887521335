<template>
  <div class="home-list-box" v-if="show">
    <van-row type="flex" justify="space-between" gutter="24">
      <van-col v-for="item in projectList" :key="item.id" span="12">
        <div v-if="item.id" class="home-item-box" @click="goLogin(item.id)">
          <div class="home-item-pic">
            <img class="img" :src="item.kv" />
          </div>
          <div class="home-item-name">
            {{ item.name }}
          </div>
        </div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import { allProject } from '@/api/api'
export default {
  data() {
    return {
      projectList: [
        { name: '肺肠安好科研项目', id: 1, kv: '/static/goBack.png' },
        { name: '测试项目', id: 2, kv: '/static/goBack.png' },
        { name: '测试2号项目', id: 3, kv: '/static/goBack.png' },
        { name: '新征程项目', id: 4, kv: '/static/goBack.png' },
        { name: '测试三号-医师资质认证', id: 5, kv: '/static/goBack.png' },
        { name: '2.0平台展示', id: 6, kv: '/static/goBack.png' },
        { name: '肺癌科研项目', id: 7, kv: '/static/goBack.png' },
        { name: '例例生辉病例调研', id: 8, kv: '/static/goBack.png' },
        { name: '天晴项目', id: 10, kv: '/static/goBack.png' },
        { name: '精锐计划', id: 11, kv: '/static/goBack.png' },
        { name: '金字塔科研项目', id: 12, kv: '/static/goBack.png' },
        { name: '怡乐相随科研项目', id: 13, kv: '/static/goBack.png' },
        { name: '止吐科研项目', id: 14, kv: '/static/goBack.png' },
        { name: '安心守护科研项目', id: 16, kv: '/static/goBack.png' },
        { name: '诺亚方舟三期', id: 17, kv: '/static/goBack.png' },
        { name: '安心首选科研项目', id: 18, kv: '/static/goBack.png' },
        { name: '2022例例生辉', id: 20, kv: '/static/goBack.png' },
        { name: '靶向治疗二期', id: 21, kv: '/static/goBack.png' },
        { name: '瑞意人生', id: 22, kv: '/static/goBack.png' },
        { name: '新瑞白科研项目.0', id: 25, kv: '/static/goBack.png' },
        { name: 'Real科研支持项目', id: 26, kv: '/static/goBack.png' },
        { name: 'asdf', id: 31, kv: '/static/goBack.png' },
        { name: '慢性常见病学科发展项目', id: 36, kv: '/static/goBack.png' },
        { name: 'IgA肾病科研数据调查研究项目', id: 38, kv: '/static/goBack.png' }
      ],
      show: false
    }
  },
  created() {
    if (location.hostname.indexOf('.papv2.sungotech.com')) {
      this.show = true
    }
    console.log('1111')
    allProject().then(res => {
      this.projectList = res.items
    })
  },
  methods: {
    goLogin(projectId) {
      location.href = `/login?project_id=${projectId}`
    }
  }
}
</script>
<style lang="scss">
//@import url(); 引入公共css类
.home-list-box {
  padding: 16px;
  .home-item-box {
    margin-bottom: 12px;
    padding: 4px 4px 12px;
    border-radius: 8px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    .home-item-pic {
      margin-bottom: 8px;
      height: 76px;
      img {
        height: 100%;
      }
    }
    .home-item-name {
      font-size: 13px;
    }
  }
}
</style>
